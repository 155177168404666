import { fontLinks } from "~/configs/fonts";
import tailwindStyles from "~/styles/tailwind.css?url";

export const stylesheetLinks = [{ rel: "stylesheet", href: tailwindStyles }];

/**
 * Docs:
 * - https://favicon.io
 * - https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs
 */
export const faviconLinks = [
  // Design and use your own favicon, or get some initial image from:
  // https://emojipedia.org
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon-32x32.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon-16x16.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/apple-touch-icon.png",
  },
];

const manifestLinks = [
  /**
   * Remember to edit the manifest
   * Alternatively this can also be generated from a Remix route
   * Like in `app/other-root-routes.server.ts`
   */
  {
    rel: "manifest",
    href: "/site.webmanifest",
  },
];

export const configDocumentLinks = [
  ...fontLinks,
  ...stylesheetLinks,
  ...faviconLinks,
  ...manifestLinks,
];
