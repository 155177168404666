/**
 * Config Fonts
 */

import brandFontStyles from "@fontsource/archivo/600.css?url";
import monoFontStyles from "@fontsource/chivo-mono/index.css?url";
import sansFontStyles from "@fontsource/inter/index.css?url";

export const fontLinks = [
  { rel: "stylesheet", href: brandFontStyles },
  { rel: "stylesheet", href: sansFontStyles },
  { rel: "stylesheet", href: monoFontStyles },
];
