import { LinksFunction, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
} from "@remix-run/react";
import { useEffect } from "react";
import GlobalLoading from "~/components/GlobalLoading";
import ThemeProvider, { Theme } from "~/components/ThemeProvider";
import { configDocumentLinks } from "~/configs/document";
import { themeCookie } from "~/server/sessions.server";
import { Toaster } from "~/shadcn/components/ui/toaster";
import { TooltipProvider } from "~/shadcn/components/ui/tooltip";

export type OutletContext = {
  state: "idle" | "loading" | "submitting";
};

// Links function to include the CSS
export const links: LinksFunction = () => {
  return configDocumentLinks;
};

// Meta function to include additional meta tags
export const meta: MetaFunction = () => {
  return [{ title: "Vendur - Home" },
    { name:"og:title", content:"Vendur"},
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const themeOrUndefined = await themeCookie.parse(request.headers.get("Cookie"));
  const theme = themeOrUndefined || "light";
  return { theme };
}

declare global {
  interface Window {
    dataLayer: Array<Record<string, any>>;
  }
}

export default function App() {
  const { theme } = (useLoaderData() as { theme: Theme }) || { theme: "light" };
  const { state } = useNavigation();
  const outletContext = { state };

  // Use useEffect to safely run client-side code
  useEffect(() => {
    // Detect client's timezone
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    document.cookie = `timezone=${clientTimezone}; path=/;`;

    // Initialize the dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    // Load GTM script dynamically
    const gtmScript = document.createElement("script");
    gtmScript.async = true;
    gtmScript.src = "https://www.googletagmanager.com/gtm.js?id=GTM-WPQ9JBM5";
    document.head.appendChild(gtmScript);
    
    return () => {
      // Clean up the script when the component unmounts
      if (document.head.contains(gtmScript)) {
        document.head.removeChild(gtmScript);
      }
    }

  }, []); // Empty dependency array ensures this runs only on initial mount

  return (
    <html lang="en" className={theme}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="og:image" content="https://vendur.io/images/official_vendur_logo.png" />
        <link rel="icon" href="https://vendur.io/favicon.ico?v=2" />
        <Meta />
        <Links />
      </head>
      <body className="antialiased">
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WPQ9JBM5"
            height="0"
            width="0"
            className="hidden invisible">
          </iframe>
        </noscript>
        <main className="text-simpleText bg-gray-100 dark:bg-gray-900">
          <ThemeProvider initialState={theme}>
            <TooltipProvider>
              <GlobalLoading>
                <Outlet context={outletContext} />
              </GlobalLoading>
              <Toaster />
            </TooltipProvider>
          </ThemeProvider>
          <ScrollRestoration />
          <Scripts />
        </main>
      </body>
    </html>
  );
}
